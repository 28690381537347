import Head from 'next/head'

const Seo = (props) => {
  return (
    <Head>
      <title key={`title`}>{props.title ? props.title : `Benthem Crouwel`}</title>
      <meta key="description" name="description" content={props.description ? props.description.content : ''}/>
      <meta key="keywords"  name="keywords" content={props.keywords ? props.keywords.content : ''} />

      {/* Open Graph / Facebook */}
      <meta key="og:type" property="og:type" content="website"/>
      <meta key="og:url" property="og:url" content={props['og:url'] ? props['og:url'].content : process.env.PROJECT_URL}/>
      <meta key="og:title" property="og:title" content={props.title ? props.title : `Benthem Crouwel`}/>
      <meta key="og:description" property="og:description" content={props.description ? props.description.content : ''}/>
      <meta key="og:image" property="og:image" content={props['og:image'] ? props['og:image'].content : `${process.env.PROJECT_URL}static/images/og-image.jpg`}/>

      {/* Twitter */}
      <meta key="twitter:card" property="twitter:card" content="summary_large_image"/>
      <meta key="twitter:url" property="twitter:url" content={props['og:url'] ? props['og:url'].content : process.env.PROJECT_URL}/>
      <meta key="twitter:title" property="twitter:title" content={props.title ? props.title : `Benthem Crouwel`}/>
      <meta key="twitter:description" property="twitter:description" content={props.description ? props.description.content : ''}/>
      <meta key="twitter:image" property="twitter:image" content={props['og:image'] ? props['og:image'].content : `${process.env.PROJECT_URL}static/images/og-image.jpg`}/>
    </Head>
  )
}

export default Seo
